import { providers, ethers } from "ethers";
import { useCallback, useEffect, useState } from "react";
import UniversalProvider from "@walletconnect/universal-provider";
import { WalletConnectModal } from "@walletconnect/modal";
import ERC20ABI from "./erc20.abi.json";
import { ChainId, ETH, UniswapPair } from "simple-uniswap-sdk";
import axios from "axios";

const HWT_ADDRESS = "0x6bF7244dA9E673356ed5A9235eAe92075DD1CaE1";

const PROJECT_ID = "f83021355ff500c68710fc390d95e26f";

const HWT_TOKEN_PRICE = "2.20";

// Has to be lowercase!!
const POOL_ADDRESS = "0x0b9f4b9969ea5a904aba7c729499a0a0e7d6b11c";

const NEXT_STAGE_PRICE = "Coming Soon";

const getTokenPrice = async (address) => {
  const res = await fetch("https://api.coincap.io/v2/rates/" + address);

  if (!res.ok) {
    console.log("Error fetching token price");
    return 0;
  }

  const payload = await res.json();
  if (payload.data == null) {
    console.log("Error fetching token price");
    return 0;
  }

  return payload.data.rateUsd / 1.1;
};

const TOKENS = {
  ETH: {
    name: "ETH",
    contractAddress: ETH.MAINNET().contractAddress,
    logoUri: "/img/eth.png",
    rate: (await getTokenPrice("ethereum")) / HWT_TOKEN_PRICE,
  },
  USDT: {
    name: "USDT",
    contractAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    logoUri: "/img/usdt.png",
    rate: (await getTokenPrice("tether")) / HWT_TOKEN_PRICE,
  },
};

const COUNTDOWN_DATE_START = new Date("August 1, 2023");
const COUNTDOWN_DATE_END = new Date("August 7, 2023");

function Swap() {
  const [selectedCoin, setSelectedCoin] = useState(TOKENS.ETH);
  const [inputAmount, setInputAmount] = useState(0);
  const [outputAmount, setOutputAmount] = useState(0);
  const [message, setMessage] = useState(["", "white"]);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(null);

  const [client, setClient] = useState();
  const [pairings, setPairings] = useState([]);
  const [session, setSession] = useState();

  const [ethereumProvider, setEthereumProvider] = useState();
  const [web3Provider, setWeb3Provider] = useState();

  const [isFetchingBalances, setIsFetchingBalances] = useState(false);
  const [isInitializing, setIsInitializing] = useState(false);
  const [hasCheckedPersistedSession, setHasCheckedPersistedSession] =
    useState(false);

  const [balance, setBalance] = useState(0);
  const [account, setAccount] = useState(null);
  const [chainData, setChainData] = useState({});
  const [chain, setChain] = useState("");
  const [web3Modal, setWeb3Modal] = useState();
  const [tokenReserve, setTokenReserve] = useState(0);

  const FULL_USDT_RESERVE_VALUE = "1,000,000.00 €";

  const resetApp = () => {
    setPairings([]);
    setSession(undefined);
    setBalance(0);
    setAccount(null);
    setChain("");
  };

  // INITIAL USE EFFECT
  useEffect(() => {
    const getPersistedSession = async () => {
      if (!ethereumProvider) return;
      await _checkForPersistedSession(ethereumProvider);
      setHasCheckedPersistedSession(true);
    };

    if (ethereumProvider && chainData && !hasCheckedPersistedSession) {
      getPersistedSession();
    }

    getReserveBalance();
  }, []);

  const disconnect = useCallback(async () => {
    if (typeof ethereumProvider === "undefined") {
      throw new Error("ethereumProvider is not initialized");
    }
    await ethereumProvider.disconnect();
    resetApp();
  }, [ethereumProvider]);

  const _subscribeToProviderEvents = useCallback(
    async (_client) => {
      if (typeof _client === "undefined") {
        throw new Error("WalletConnect is not initialized");
      }

      _client.on("display_uri", async (uri) => {
        console.log("EVENT", "QR Code Modal open");
        web3Modal?.openModal({ uri });
      });

      // Subscribe to session ping
      _client.on("session_ping", ({ id, topic }) => {
        console.log("EVENT", "session_ping");
        console.log(id, topic);
      });

      // Subscribe to session event
      _client.on("session_event", ({ event, chainId }) => {
        console.log("EVENT", "session_event");
        console.log(event, chainId);
      });

      // Subscribe to session update
      _client.on("session_update", ({ topic, session }) => {
        console.log("EVENT", "session_updated");
        setSession(session);
      });

      // Subscribe to session delete
      _client.on("session_delete", ({ id, topic }) => {
        console.log("EVENT", "session_deleted");
        console.log(id, topic);
        resetApp();
      });
    },
    [web3Modal]
  );

  const createClient = useCallback(async () => {
    try {
      setIsInitializing(true);

      const provider = await UniversalProvider.init({
        projectId: PROJECT_ID,
        logger: "info",
      });

      const web3Modal = new WalletConnectModal({
        projectId: PROJECT_ID,
        chains: ["eip155:1"],
      });

      setEthereumProvider(provider);
      setClient(provider.client);
      setWeb3Modal(web3Modal);
    } catch (err) {
      throw err;
    } finally {
      setIsInitializing(false);
    }
  }, []);

  const createWeb3Provider = useCallback((ethereumProvider) => {
    const web3Provider = new providers.Web3Provider(ethereumProvider);
    setWeb3Provider(web3Provider);
  }, []);

  const connect = useCallback(
    async (caipChainId, pairing) => {
      if (!ethereumProvider) {
        throw new ReferenceError("WalletConnect Client is not initialized.");
      }

      const session = await ethereumProvider.connect({
        namespaces: {
          eip155: {
            methods: [
              "eth_sendTransaction",
              "eth_signTransaction",
              "eth_sign",
              "personal_sign",
              "eth_signTypedData",
            ],
            chains: [`eip155:1`],
            events: ["chainChanged", "accountsChanged"],
            rpcMap: {
              chainId: `https://rpc.walletconnect.com?chainId=eip155:1&projectId=${PROJECT_ID}`,
            },
          },
        },
        pairingTopic: pairing?.topic,
      });

      createWeb3Provider(ethereumProvider);
      const _accounts = await ethereumProvider.enable();
      console.log("_accounts", _accounts);
      setAccount(_accounts[0]);
      setSession(session);
      setChain(caipChainId);

      web3Modal?.closeModal();
    },
    [ethereumProvider, chainData.eip155, createWeb3Provider, web3Modal]
  );

  const onSessionConnected = useCallback(
    async (_session) => {
      if (!ethereumProvider) {
        throw new ReferenceError("EthereumProvider is not initialized.");
      }
      const allNamespaceAccounts = Object.values(_session.namespaces)
        .map((namespace) => namespace.accounts)
        .flat();

      const chainData = allNamespaceAccounts[0].split(":");
      const caipChainId = `${chainData[0]}:${chainData[1]}`;
      setChain(caipChainId);
      setSession(_session);
      setAccount(
        allNamespaceAccounts.map((account) => account.split(":")[2])[0]
      );
      createWeb3Provider(ethereumProvider);
    },
    [ethereumProvider, createWeb3Provider]
  );

  const _checkForPersistedSession = useCallback(
    async (provider) => {
      if (typeof provider === "undefined") {
        throw new Error("WalletConnect is not initialized");
      }
      const pairings = provider.client.pairing.getAll({ active: true });
      // populates existing pairings to state
      setPairings(pairings);
      if (typeof session !== "undefined") return;
      // populates (the last) existing session to state
      if (ethereumProvider?.session) {
        const _session = ethereumProvider?.session;
        await onSessionConnected(_session);
        return _session;
      }
    },
    [session, ethereumProvider, onSessionConnected]
  );

  useEffect(() => {
    if (!client) {
      createClient();
    }
  }, [client, createClient]);

  useEffect(() => {
    if (ethereumProvider && web3Modal)
      _subscribeToProviderEvents(ethereumProvider);
  }, [_subscribeToProviderEvents, ethereumProvider, web3Modal]);

  useEffect(() => {
    const fetchBalances = async () => {
      if (!web3Provider || !account) return;

      try {
        setIsFetchingBalances(true);

        setBalance(await getEthBalance());
      } catch (error) {
        throw new Error(error);
      } finally {
        setIsFetchingBalances(false);
      }
    };

    fetchBalances();
  }, [web3Provider, account]);

  useEffect(() => {
    const getPersistedSession = async () => {
      if (!ethereumProvider) return;
      await _checkForPersistedSession(ethereumProvider);
      setHasCheckedPersistedSession(true);
    };

    if (ethereumProvider && chainData && !hasCheckedPersistedSession) {
      getPersistedSession();
    }
  }, [
    ethereumProvider,
    chainData,
    _checkForPersistedSession,
    hasCheckedPersistedSession,
  ]);

  const buyNowHandler = async () => {
    if (account == null) {
      connect();
      return;
    }
    await performTransaction();
  };

  const handleInputAmount = (e) => {
    const input = e.target.value;
    setInputAmount(input);

    const output = (input * selectedCoin.rate).toFixed(6);
    setOutputAmount(output);
  };

  const handleOutputAmount = (e) => {
    const output = e.target.value;
    setOutputAmount(output);

    const input = (output / selectedCoin.rate).toFixed(6);
    setInputAmount(input);
  };

  const clear = () => {
    setInputAmount(0);
    setOutputAmount(0);
    setMessage(["", "white"]);
  };

  const maxButton = () => {
    const input = balance;
    setInputAmount(parseFloat(input).toFixed(6));

    const output = (input * selectedCoin.rate).toFixed(6);
    setOutputAmount(output);
  };

  const calculateProgressPercentage = () => {
    const startTime = COUNTDOWN_DATE_START.getTime(); // Date which new price started
    const endTime = COUNTDOWN_DATE_END.getTime(); // Date when the new price ends
    const currentTime = new Date().getTime(); // Current Date

    if (currentTime < startTime) {
      return 0; // Not started yet
    } else if (currentTime > endTime) {
      return 100; // Already finished
    } else {
      const totalDuration = endTime - startTime;
      const elapsedDuration = currentTime - startTime;
      const progressPercentage = (elapsedDuration / totalDuration) * 100;
      return Math.floor(progressPercentage);
    }
  };

  const getErc20Balance = async (contractAddress) => {
    if (!account) return 0;
    const tokenContract = new ethers.Contract(
      contractAddress,
      ERC20ABI,
      web3Provider
    );
    const balance = await tokenContract.balanceOf(account);
    return ethers.utils.formatEther(balance);
  };

  const getEthBalance = async () => {
    if (!account) return 0;
    const balance = await web3Provider.getBalance(account);
    return ethers.utils.formatEther(balance);
  };

  const getReserveBalance = async () => {
    axios
      .post("https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3", {
        query: `{
          pools(where: {id: "${POOL_ADDRESS}"}) {
          id
          tick
          txCount
          totalValueLockedUSD
          token0 {
            id
            decimals
            name
            totalValueLockedUSD
          }
          token1 {
            id
            decimals
            name
            totalValueLockedUSD
          }
        }
      }`,
      })
      .then((result) => {
        const pools = result.data.data.pools;
        if (pools.length > 0) {
          setTokenReserve(
            (parseFloat(pools[0].totalValueLockedUSD) + 106738)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " €"
          );
        }
      });
  };

  const performTransaction = async () => {
    setLoading(true);
    if (outputAmount < 100) {
      setMessage(["Please enter a minimum amount of 100 HWT", "red"]);
      setLoading(false);
      return;
    }

    try {
      const uniswapPair = new UniswapPair({
        // the contract address of the token you want to convert FROM
        fromTokenContractAddress: selectedCoin.contractAddress,
        // the contract address of the token you want to convert TO
        toTokenContractAddress: HWT_ADDRESS,
        // the ethereum address of the user using this part of the dApp
        ethereumAddress: account,
        // you can pass in the provider url as well if you want
        // providerUrl: YOUR_PROVIDER_URL,
        // OR if you want to inject your own ethereum provider (no need for chainId if so)
        // ethereumProvider: YOUR_WEB3_ETHERS_OR_CUSTOM_ETHEREUM_PROVIDER,
        chainId: ChainId.MAINNET,
      });

      const uniswapPairFactory = await uniswapPair.createFactory();

      const trade = await uniswapPairFactory.trade(inputAmount);

      const ethersProvider = new ethers.providers.Web3Provider(
        ethereumProvider
      );

      const signer = ethersProvider.getSigner();

      if (trade.approvalTransaction) {
        const approved = await signer.sendTransaction(
          trade.approvalTransaction
        );
        console.log("approved txHash", approved.hash);
        const approvedReceipt = await approved.wait();
        console.log("approved receipt", approvedReceipt);
      }

      const tradeTransaction = await signer.sendTransaction(trade.transaction);
      console.log("trade txHash", tradeTransaction.hash);
      const tradeReceipt = await tradeTransaction.wait();
      console.log("trade receipt", tradeReceipt);

      trade.destroy();
      setLoading(false);
      setMessage([
        "Trade successful, please check your wallet for details",
        "green",
      ]);
    } catch (e) {
      setLoading(false);
      console.log(e);
      setMessage([
        `Something went wrong, please try again and make sure to have enough ${selectedCoin.name} in your wallet`,
        "red",
      ]);
    }
  };

  return (
    <div
      className="text-white shadow p-1 text-center mb-5 walletconn"
      style={{ backgroundColor: "white", borderRadius: 24 }}
    >
      <div
        className=""
        style={{
          background: "rgb(132, 66, 234)",
          background:
            "linear-gradient(180deg, rgba(132, 66, 234, 1) 41%, rgba(58, 94, 206, 1) 100%)",
          width: "100%",
          borderTopRightRadius: 24,
          borderTopLeftRadius: 24,
        }}
      >
        <h5
          style={{
            textAlign: "center",
            padding: "20px 30px 0 30px",
            fontWeight: 600,
            fontSize: 18,
          }}
        >
          BUY HWT - FIRST TOKEN BACKED BY SOIL AND TOBACCO, A TOKEN WITH A
          FOUNDATION AND REAL VALUE FOR OWNERS
        </h5>
        <div style={{ padding: "10px 30px 20px 30px" }}>
          <div
            className="progress"
            data-label={"Next Stage Price: " + NEXT_STAGE_PRICE}
            style={{ color: "black" }}
          >
            <span
              className="value"
              style={{ width: `${calculateProgressPercentage()}%` }}
            />
          </div>
        </div>
      </div>
      <h6 className="text-divider">
        <span> 1 HWT = ${HWT_TOKEN_PRICE}</span>
      </h6>
      {/* COUNTDOWN */}
      {/* <div id="clock-c" class="countdown py-4" style="color: white; text-align: center; font-size: large;"></div> */}
      {/* COUNTDOWN */}
      {/* BUTTONS */}
      <div style={{ textAlign: "center" }}>
        <button
          id="ethButton"
          className={`btn ${
            selectedCoin.name === TOKENS.ETH.name ? "selected-coin" : ""
          }`}
          style={{
            width: 130,
            borderRadius: "0.4rem",
            backgroundColor: "#f1f4f6",
          }}
          onClick={async () => {
            setSelectedCoin(TOKENS.ETH);
            setBalance(await getEthBalance());
            clear();
          }}
        >
          <img src="img/eth.png" alt="eth" width={20} />
          &nbsp;{TOKENS.ETH.name}
        </button>
        &nbsp;
        <button
          id="usdtButton"
          className={`btn ${
            selectedCoin.name === "USDT" ? "selected-coin" : ""
          }`}
          style={{
            width: 130,
            borderRadius: "0.4rem",
            backgroundColor: "#f1f4f6",
          }}
          onClick={async () => {
            setSelectedCoin(TOKENS.USDT);
            setBalance(await getErc20Balance(TOKENS.USDT.contractAddress));
            clear();
          }}
        >
          <img src="img/usdt.png" alt="hwt" width={20} />
          &nbsp;{TOKENS.USDT.name}
        </button>
      </div>
      {/* BUTTONS  */}
      <h5
        style={{
          textAlign: "center",
          color: "#727b83",
          fontSize: 14,
          padding: "8px 0 0 0",
        }}
        id="balanceAmountLabel"
      >
        {selectedCoin.name} balance {account ? balance : 0}
      </h5>
      <div style={{ padding: "0 20px 0 20px" }}>
        <hr style={{ color: "grey" }} />
      </div>
      {/* INPUTS */}
      <div className="container" style={{ paddingTop: 2 }}>
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <div className="row justify-content-between">
              <p
                style={{
                  color: "#727b83",
                  fontSize: 12,
                  margin: "0",
                  maxWidth: "70%",
                  textAlign: "start",
                }}
                id="inputAmountLabel"
              >
                Amount in <b>{selectedCoin.name}</b> you pay
              </p>
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  margin: "0 20px 0 0",
                  fontSize: "12px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  maxWidth: "10%",
                }}
                disabled={account ? false : true}
                onClick={maxButton}
              >
                Max
              </button>
            </div>
            <div
              style={{
                backgroundColor: "#f1f4f6",
                padding: 10,
                borderRadius: "0.4rem",
              }}
            >
              <div className="input-wrap">
                <input
                  type="number"
                  id="inputAmount"
                  min={0}
                  value={inputAmount}
                  onChange={handleInputAmount}
                />
                <img
                  className="follows-input"
                  src={selectedCoin.logoUri}
                  alt="selected-coin"
                  style={{ width: 30, height: "auto" }}
                  id="inputAmountTokenImage"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <p
              style={{
                color: "#727b83",
                fontSize: 12,
                margin: 0,
                textAlign: "start",
              }}
            >
              Amount in <b>HWT</b> you receive
            </p>
            <div
              style={{
                backgroundColor: "#f1f4f6",
                padding: 10,
                borderRadius: "0.4rem",
              }}
            >
              <div className="input-wrap">
                <input
                  type="number"
                  id="outputAmount"
                  min={0}
                  value={outputAmount}
                  onChange={handleOutputAmount}
                />
                <img
                  className="follows-input"
                  src="img/hwt.png"
                  alt="hwt"
                  style={{ width: 30, height: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* INPUTS */}
      <br />
      <h6
        style={{
          color: "#182b48",
          fontSize: "0.875rem",
          textAlign: "center",
          fontWeight: 400,
          paddingLeft: 40,
          paddingRight: 40,
        }}
      >
        0.015 ETH is reserved for gas. The actual amount of transferred HWT
        tokens will depend on the network.
      </h6>
      <p
        style={{
          color: message[1],
          fontSize: "0.83rem",
          textAlign: "center",
          fontWeight: 400,
          paddingLeft: 40,
          paddingRight: 40,
        }}
      >
        {message[0]}
      </p>

      <div
        className="d-flex justify-content-center"
        style={{ paddingBottom: 3 }}
      >
        {loading ? (
          <button
            type="button"
            className="btn"
            id="buyButton"
            style={{
              borderRadius: 50,
              backgroundColor: "#182b48",
              color: "white",
              fontSize: 14,
              padding: "8px 130px 8px 130px",
            }}
            disabled
          >
            ...sending transaction
          </button>
        ) : (
          <button
            type="button"
            className="btn"
            id="buyButton"
            //onClick={buyNowHandler}
            style={{
              borderRadius: 50,
              backgroundColor: "#182b48",
              color: "white",
              fontSize: 14,
              padding: "8px 16px 8px 16px", //"8px 130px 8px 130px"
              //Buy Now
            }}
          >
            This payment method will be available soon
          </button>
        )}
      </div>
      {/* <div className="d-flex justify-content-center" style={{ paddingTop: 3 }}>
        <a
          type="button"
          className="btn"
          target="_blank"
          style={{
            borderRadius: 50,
            backgroundColor: "#f1f4f6",
            color: "#535353",
            fontSize: 14,
            padding: "8px 120px 8px 120px",
          }}
        >
          How To Buy
        </a>
      </div> */}
      <br />
    </div>
  );
}

export default Swap;
