import { useEffect } from "react";
import Swap from "./Swap";
import MyCountdown from "./MyCountdown";
import Aos from "aos";
import Swiper from "swiper";
import glightboxMin from "glightbox";
import Isotope from "isotope-layout";
import PureCounter from "@srexi/purecounterjs";
import NewsSlideShow from "./slideshow";

function Home() {
  useEffect(() => {
    new PureCounter();
    window.goToLinkedIn = (link) => {
      window.location.href = link;
    };

    /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      } else {
        return document.querySelector(el);
      }
    };

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
      let selectEl = select(el, all);
      if (selectEl) {
        if (all) {
          selectEl.forEach((e) => e.addEventListener(type, listener));
        } else {
          selectEl.addEventListener(type, listener);
        }
      }
    };

    /**
     * Easy on scroll event listener
     */
    const onscroll = (el, listener) => {
      el.addEventListener("scroll", listener);
    };

    /**
     * Navbar links active state on scroll
     */
    let navbarlinks = select("#navbar .scrollto", true);
    const navbarlinksActive = () => {
      let position = window.scrollY + 200;
      navbarlinks.forEach((navbarlink) => {
        if (!navbarlink.hash) return;
        let section = select(navbarlink.hash);
        if (!section) return;
        if (
          position >= section.offsetTop &&
          position <= section.offsetTop + section.offsetHeight
        ) {
          navbarlink.classList.add("active");
        } else {
          navbarlink.classList.remove("active");
        }
      });
    };
    navbarlinksActive();
    onscroll(document, navbarlinksActive);

    /**
     * Scrolls to an element with header offset
     */
    const scrollto = (el) => {
      let header = select("#header");
      let offset = header.offsetHeight;

      if (!header.classList.contains("header-scrolled")) {
        offset -= 16;
      }

      let elementPos = select(el).offsetTop;
      window.scrollTo({
        top: elementPos - offset,
        behavior: "smooth",
      });
    };

    /**
     * Toggle .header-scrolled class to #header when page is scrolled
     */
    let selectHeader = select("#header");
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add("header-scrolled");
        } else {
          selectHeader.classList.remove("header-scrolled");
        }
      };
      headerScrolled();
      onscroll(document, headerScrolled);
    }

    /**
     * Back to top button
     */
    let backtotop = select(".back-to-top");
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add("active");
        } else {
          backtotop.classList.remove("active");
        }
      };
      toggleBacktotop();
      onscroll(document, toggleBacktotop);
    }

    /**
     * Mobile nav toggle
     */
    on("click", ".mobile-nav-toggle", function (e) {
      select("#navbar").classList.toggle("navbar-mobile");
      this.classList.toggle("bi-list");
      this.classList.toggle("bi-x");
    });

    /**
     * Mobile nav dropdowns activate
     */
    on(
      "click",
      ".navbar .dropdown > a",
      function (e) {
        if (select("#navbar").classList.contains("navbar-mobile")) {
          e.preventDefault();
          this.nextElementSibling.classList.toggle("dropdown-active");
        }
      },
      true
    );

    /**
     * Scrool with ofset on links with a class name .scrollto
     */
    on(
      "click",
      ".scrollto",
      function (e) {
        if (select(this.hash)) {
          e.preventDefault();

          let navbar = select("#navbar");
          if (navbar.classList.contains("navbar-mobile")) {
            navbar.classList.remove("navbar-mobile");
            let navbarToggle = select(".mobile-nav-toggle");
            navbarToggle.classList.toggle("bi-list");
            navbarToggle.classList.toggle("bi-x");
          }
          scrollto(this.hash);
        }
      },
      true
    );

    /**
     * Scroll with ofset on page load with hash links in the url
     */
    if (window.location.hash) {
      if (select(window.location.hash)) {
        scrollto(window.location.hash);
      }
    }

    /**
     * Preloader
     */
    let preloader = select("#preloader");
    if (preloader) {
      preloader.remove();
    }

    /**
     * Testimonials slider
     */
    new Swiper(".testimonials-slider", {
      speed: 600,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 40,
        },

        1200: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      },
    });

    /**
     * Porfolio isotope and filter
     */
    let portfolioContainer = select(".portfolio-container");
    if (portfolioContainer) {
      let portfolioIsotope = new Isotope(portfolioContainer, {
        itemSelector: ".portfolio-item",
      });

      let portfolioFilters = select("#portfolio-flters li", true);

      on(
        "click",
        "#portfolio-flters li",
        function (e) {
          e.preventDefault();
          portfolioFilters.forEach(function (el) {
            el.classList.remove("filter-active");
          });
          this.classList.add("filter-active");

          portfolioIsotope.arrange({
            filter: this.getAttribute("data-filter"),
          });
          portfolioIsotope.on("arrangeComplete", function () {
            Aos.refresh();
          });
        },
        true
      );
    }

    /**
     * Initiate portfolio lightbox
     */
    const portfolioLightbox = glightboxMin({
      selector: ".portfolio-lightbox",
    });

    /**
     * Portfolio details slider
     */
    new Swiper(".portfolio-details-slider", {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
    });

    /**
     * Animation on scroll
     */
    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  }, []);
  return (
    <>
      {/* ======= Header ======= */}
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center justify-content-between">
          <h1 className="logo">
            <a href="index.html">
              <img src="img/htwlogo.png" alt="" />
            </a>
          </h1>
          {/* Uncomment below if you prefer to use an image logo */}
          {/* <a href="index.html" class="logo"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>*/}
          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a className="nav-link scrollto" href="/">
                  Home
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#about">
                  About
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#features">
                  Features
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#team">
                  Team
                </a>
              </li>
              <li>
                <a href="files/whitepaper.pdf" target="_blank">
                  Whitepaper
                </a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle" />
          </nav>
          {/* .navbar */}
        </div>
      </header>
      {/* End Header */}
      {/* ======= Hero Section ======= */}
      <section id="hero" className="d-flex align-items-center">
        <div
          className="container-fluid"
          data-aos="fade-up"
          style={{ paddingTop: 20 }}
        >
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 pt-3 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-start">
              <h1>HUQQA WORLD TOKEN</h1>
              <br />
              <h2>
                We have supply chains with over 12,000 hookah bars and 3500
                hookah stores. We also have contracts with over 100 wholesalers.
                Our vision is to build 1000 hectares of land in South America
                and produce our own tobacco.
                <br />
                <br />
                Buy the worlds first token backed by real world assets (RWAs)
                like soil and tobacco, a token with a foundation and real value
                for owners
              </h2>
              <div>
                <a
                  href="files/whitepaper.pdf"
                  className="btn-get-started"
                  target="_blank"
                >
                  Whitepaper <img width={25} className="retroArrow" alt="" />
                </a>
              </div>
            </div>
            {/* <Swap /> */}
            <div className="col-xl-5 col-lg-6 pt-3 pt-lg-0 order-1 order-lg-1 d-flex flex-column justify-content-start">
              <h1>HWT is Listed Now!</h1>
              <br />
              <h2>
                Be part of a revolutionary step in our journey towards real-word
                assets! We're thrilled to announce that our highly anticipated
                HUQQA WORLD TOKEN (HWT) is now listed on BitMart. This is your
                chance to join a dynamic and rapidly growing community.
                <br />
                <br />
                Get ready to trade, as this listing is set to elevate HWT to new
                heights. Don't miss out on this incredible opportunity to be
                part of the future!
              </h2>
              <div>
                <a
                  href="https://www.bitmart.com/trade/en-US?symbol=HWT_USDT"
                  className="btn-get-started"
                  target="_blank"
                  style={{ color: "lime", borderColor: "lime" }}
                >
                  HWT is listed on BitMart - Trade Now!
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Hero */}
      <main id="main">
        {/* ======= Clients Section ======= */}
        <section id="clients" className="clients clients">
          <div className="container">
            <div className="row justify-content-between">
              <a
                className="col-lg-3 col-md-8 col-12"
                href="https://nargilem.com"
                target="_blank"
              >
                <img
                  src="img/nargilem.png"
                  className="img-fluid"
                  alt=""
                  data-aos="zoom-in"
                  data-aos-delay={200}
                />
              </a>
              <a
                className="col-lg-2 col-md-4 col-12"
                href="https://nargilem.com/"
                target="_blank"
              >
                <img
                  src="img/nps_logo.png"
                  className="img-fluid"
                  alt=""
                  data-aos="zoom-in"
                  data-aos-delay={300}
                />
              </a>
              <a
                className="col-lg-3 col-md-8 col-12"
                href="https://nargilem.com"
                target="_blank"
              >
                <img
                  src="img/kanka.png"
                  className="img-fluid"
                  alt=""
                  data-aos="zoom-in"
                  data-aos-delay={300}
                />
              </a>
            </div>
          </div>
        </section>
        {/* End Clients Section */}
        {/* ======= About Section ======= */}
        <section id="about" className="about">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 order-1 order-lg-2"
                data-aos="zoom-in"
                data-aos-delay={150}
              >
                <img src="img/hwt.png" className="img-fluid" />
              </div>
              <div
                className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
                data-aos="fade-right"
              >
                <h3>The Huqqa World Token</h3>
                <p className="fst-italic">
                  Why ICO? There are many arguments why we have chosen to do
                  initial coin offering instead of alternatives ways to raise
                  money.
                </p>
                <ul>
                  <li>
                    <i className="bi bi-check-circle" /> First, an initial coin
                    offer not only allows us to onboard participants from
                    different regions but also to incentivise them to promote
                    Huqqa World Token products via Franchise shops that act as
                    real world assets
                  </li>
                  <li>
                    <i className="bi bi-check-circle" /> Tokens provide
                    liquidity since they can be traded on various exchanges
                    after the ICO
                  </li>
                  <li>
                    <i className="bi bi-check-circle" /> Participants can
                    liquidate their position like holding shares in a publicly
                    listed company or any other real world asset
                  </li>
                  <li>
                    <i className="bi bi-check-circle" /> Finally, the tokens
                    smart contract enables an easy transparent payment method,
                    which would be hard to process without the usage of
                    blockchain technology.
                  </li>
                </ul>
                <a
                  href="files/whitepaper.pdf"
                  target="_blank"
                  className="read-more"
                >
                  Read Whitepaper
                  <i className="bi bi-long-arrow-right" />
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* End About Section */}
        {/* ======= Features Section ======= */}
        <section id="features" className="features">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Features</h2>
              <p>
                During the initial coin offering (ICO), Nargilem as a company
                will issue virtual currency tokens called Huqqa World Token
                (HWT) on the blockchain operated by a smart contract. Tokens
                thereby represent stakes in our extensive hookah supply chain,
                ensuring that all tokens are supported by substantial real world
                assets.
              </p>
            </div>
            <div className="row">
              <div
                className="image col-lg-6 order-2 order-lg-1"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <br />
                <div className="section-title">
                  <h2>Token Distribution</h2>
                </div>
                <img
                  src="img/hwt_donatChart.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="col-lg-6 order-1 order-lg-2 d-flex flex-column align-items-lg-center">
                <div
                  className="icon-box mt-5 mt-lg-0"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <i className="bx bx-receipt" />
                  <h4>Increase A Coin’s Value</h4>
                  <p>
                    Deflationary cryptocurrencies focus on reducing their supply
                    in the market, increasing their scarcity, and heightening
                    their demand. Why, you may ask? It is common knowledge that
                    things that are rarer to get are more enticing than those
                    which are readily available.
                  </p>
                </div>
                <div
                  className="icon-box mt-5"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <i className="bx bx-cube-alt" />
                  <h4>Generating Profits</h4>
                  <p>
                    During the last bull run, deflationary tokens have been
                    taking the spotlight. This element directly contributes to
                    investor interests as they amass more profits. Another
                    scenario for the same is if a platform decides to buy back
                    coins from holders. The whole process leading to the coin
                    burning will profit those who choose to short their coins.
                  </p>
                </div>
                <div
                  className="icon-box mt-5"
                  data-aos="fade-up"
                  data-aos-delay={300}
                >
                  <i className="bx bx-analyse" />
                  <h4>Removing Extras from the Market</h4>
                  <p>
                    Unsold tokens in circulation are detrimental to the progress
                    of a cryptocurrency. Deflationary mechanisms help a project
                    to remove them from circulation instead of flooding the
                    market.
                  </p>
                </div>
                <div
                  className="icon-box mt-5"
                  data-aos="fade-up"
                  data-aos-delay={400}
                >
                  <i className="bx bx-shield" />
                  <h4>Distribution</h4>
                  <p>
                    The Huqqa World Token is limited to a maximum supply of
                    500.000.000 HWT and is an ERC20 token. This supply
                    limitation is implemented into the smart contract and cannot
                    be changed or manipulated by HWT or any third party.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Features Section */}
        {/* ======= Counts Section ======= */}
        <section id="counts" className="counts">
          <div className="container">
            <div className="row counters">
              <div className="col-lg-3 col-6 text-center">
                <span
                  data-purecounter-start={0}
                  data-purecounter-end={25}
                  data-purecounter-duration={1}
                  className="purecounter"
                />
                <p>Years Experience</p>
              </div>
              <div className="col-lg-3 col-6 text-center">
                <span
                  data-purecounter-start={0}
                  data-purecounter-end={15000}
                  data-purecounter-duration={1}
                  className="purecounter"
                />
                <p>Customers every month</p>
              </div>
              <div className="col-lg-3 col-6 text-center">
                <span
                  data-purecounter-start={0}
                  data-purecounter-end={1200000}
                  data-purecounter-duration={1}
                  className="purecounter"
                />
                <p>Cans Every Year</p>
              </div>
              <div className="col-lg-3 col-6 text-center">
                <span
                  data-purecounter-start={0}
                  data-purecounter-end={15}
                  data-purecounter-duration={1}
                  className="purecounter"
                />
                <p>Hard Workers</p>
              </div>
            </div>
          </div>
        </section>
        {/* End Counts Section */}
        {/* ======= Team Section ======= */}
        <section id="team" className="team section-bg">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Our Team</h2>
              <p>
                The HWT team is a professional crypto team and consists of
                members from a wide range of industries and specialist areas.
                Altogether, the team consists of almost 10 members who are
                especially experienced with cryptocurrencies as well as real
                world assets and spread across more than four countries. We are
                united by our belief in blockchain technology and the
                fundamental, forward-looking significance of cryptocurrencies.
                The healthy relationship and the close interlocking of the
                employees among each other, as well as the different experience
                values, enables an efficient and future-oriented design of
                hwt24.io
              </p>
            </div>
          </div>
        </section>
        {/* ======= Clients Section ======= */}
        <section id="clients" className="clients clients">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Who's talking about HWT?</h2>
            </div>
            <div className="row justify-content-evenly">
              <a
                className="col-lg-3 col-md-4 col-6"
                href="https://finance.yahoo.com/hookah-bars-blockchain-hwts-vision-133100490.html?guccounter=1"
                target="_blank"
              >
                <img
                  src="img/news_logos/yahoo_finance.png"
                  className="img-fluid news-logo"
                  alt=""
                  data-aos="zoom-in"
                  data-aos-delay={200}
                />
              </a>
              <a
                className="col-lg-3 col-md-4 col-6"
                href="https://medium.com/@marketing_18251/the-impact-of-cryptocurrencies-on-global-and-local-payments-9d42d1bdbf5a"
                target="_blank"
              >
                <img
                  src="img/news_logos/medium.png"
                  className="img-fluid news-logo"
                  alt=""
                  data-aos="zoom-in"
                  data-aos-delay={300}
                />
              </a>
              <a
                className="col-lg-3 col-md-4 col-6"
                href="https://cryptoadventure.com/from-hookah-bars-to-blockchain-hwts-vision-for-a-thriving-crypto-community"
                target="_blank"
              >
                <img
                  src="img/news_logos/cryptoadventure.png"
                  className="img-fluid news-logo"
                  alt=""
                  data-aos="zoom-in"
                  data-aos-delay={300}
                />
              </a>
              <a
                className="col-lg-3 col-md-4 col-6"
                href="https://markets.businessinsider.com/news/stocks/from-hookah-bars-to-blockchain-hwt-s-vision-for-a-thriving-crypto-community-1033005497"
                target="_blank"
              >
                <img
                  src="img/news_logos/business-insider.png"
                  className="img-fluid news-logo"
                  alt=""
                  data-aos="zoom-in"
                  data-aos-delay={300}
                />
              </a>
              <a
                className="col-lg-3 col-md-4 col-6"
                href="https://www.tradingview.com/news/reuters.com,2024-01-29:newsml_GNX4zS4fB:0-from-hookah-bars-to-blockchain-hwt-s-vision-for-a-thriving-crypto-community/"
                target="_blank"
              >
                <img
                  src="img/news_logos/trading-view.png"
                  className="img-fluid news-logo"
                  alt=""
                  data-aos="zoom-in"
                  data-aos-delay={300}
                />
              </a>
              <a
                className="col-lg-3 col-md-4 col-6"
                href="https://www.benzinga.com/pressreleases/24/01/g36823555/from-hookah-bars-to-blockchain-hwts-vision-for-a-thriving-crypto-community"
                target="_blank"
              >
                <img
                  src="img/news_logos/benzinga.png"
                  className="img-fluid news-logo"
                  alt=""
                  data-aos="zoom-in"
                  data-aos-delay={300}
                />
              </a>

              <a
                className="col-lg-3 col-md-4 col-6"
                href="https://www.streetinsider.com/Globe+Newswire/From+Hookah+Bars+to+Blockchain%3A+HWTs+Vision+for+a+Thriving+Crypto+Community/22685709.html"
                target="_blank"
              >
                <img
                  src="img/news_logos/street-insider.png"
                  className="img-fluid news-logo"
                  alt=""
                  data-aos="zoom-in"
                  data-aos-delay={300}
                />
              </a>

              <a
                className="col-lg-3 col-md-4 col-6"
                href="https://www.globenewswire.com/news-release/2024/01/29/2818924/0/en/From-Hookah-Bars-to-Blockchain-HWT-s-Vision-for-a-Thriving-Crypto-Community.html?f=22&fvtc=5&fvtv=33969883"
                target="_blank"
              >
                <img
                  src="img/news_logos/globe-news-wire.png"
                  className="img-fluid news-logo"
                  alt=""
                  data-aos="zoom-in"
                  data-aos-delay={300}
                />
              </a>

              <a
                className="col-lg-3 col-md-4 col-6"
                href="https://apnews.com/press-release/globenewswire-mobile/blockchain-a7cc720f0259bf6763bad943866155f6"
                target="_blank"
              >
                <img
                  src="img/news_logos/ap-news.png"
                  className="img-fluid news-logo"
                  alt=""
                  data-aos="zoom-in"
                  data-aos-delay={300}
                />
              </a>
              <a
                className="col-lg-3 col-md-4 col-6"
                href="https://www.morningstar.com/news/globe-newswire/9027436/from-hookah-bars-to-blockchain-hwts-vision-for-a-thriving-crypto-community"
                target="_blank"
              >
                <img
                  src="img/news_logos/morningstar.png"
                  className="img-fluid news-logo"
                  alt=""
                  data-aos="zoom-in"
                  data-aos-delay={300}
                />
              </a>
              <a
                className="col-lg-3 col-md-4 col-6"
                href="https://cryptonews.direct/from-hookah-bars-to-blockchain-hwts-vision-for-a-thriving-crypto-community/"
                target="_blank"
              >
                <img
                  src="img/news_logos/crypto-news.png"
                  className="img-fluid news-logo"
                  alt=""
                  data-aos="zoom-in"
                  data-aos-delay={300}
                />
              </a>
              <a
                className="col-lg-3 col-md-4 col-6"
                href="https://www.tradingview.com/news/reuters.com,2024-01-29:newsml_GNX4zS4fB:0-from-hookah-bars-to-blockchain-hwt-s-vision-for-a-thriving-crypto-community/"
                target="_blank"
              >
                <img
                  src="img/news_logos/reuters.png"
                  className="img-fluid news-logo"
                  alt=""
                  data-aos="zoom-in"
                  data-aos-delay={300}
                />
              </a>
            </div>
          </div>
        </section>
        {/* End Clients Section */}
        {/* End Team Section */}
        <section id="news-section" className="news section-bg">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Latest News</h2>
              <p>
                Discover the latest news from Huqqa World Token! Explore our
                news section for the newest updates and insights into the
                ever-evolving world of HWT.
              </p>
            </div>
            <div className="row">
              <div className="col-12">
                <NewsSlideShow />
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* End #main */}
      {/* ======= Footer ======= */}
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 footer-contact">
                <h3 className="logo">
                  <img src="img/htwlogo.png" alt="" width="200px" />
                </h3>
                <p>
                  <strong>Email: </strong>
                  <a href="mailto:info@hwt24.io" className="footer-email">
                    info@hwt24.io
                  </a>
                </p>
              </div>
              <div className="col-lg-4 col-md-6 footer-links">
                <h4>More links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right" />
                    <a href="/impressum.html">Impressum</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />
                    <a href="files/whitepaper.pdf" target="_blank">
                      Whitepaper
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />
                    <a href="files/tos.pdf" target="_blank">
                      Terms of Service
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />
                    <a href="https://t.me/HuqqaWorld">Telegram Channel</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6 footer-newsletter">
                <h4>Where to follow us</h4>
                <p>
                  Follow us on social media and telegram to get the latest news
                  on Huqqa World Token
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright-wrap d-md-flex py-4">
            <div className="me-md-auto text-center text-md-start">
              <div className="copyright">
                ©2024{" "}
                <strong>
                  <span>Huqqa World</span>
                </strong>
                . All Rights Reserved
              </div>
            </div>
            <div className="social-links text-center text-md-right pt-3 pt-md-0">
              <a href="https://twitter.com/HuqqaWorld24/" className="twitter">
                <i className="bx bxl-twitter" />
              </a>
              <a
                href="https://www.instagram.com/huqqaworld.io/"
                className="instagram"
              >
                <i className="bx bxl-instagram" />
              </a>
              <a href="https://medium.com/@marketing_18251" className="medium">
                <i className="bx bxl-medium" />
              </a>
              <a href="https://t.me/HuqqaWorld" className="telegram">
                <i className="bx bxl-telegram" />
              </a>
            </div>
          </div>
        </div>
      </footer>
      {/* End Footer */}
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short" />
      </a>
      <div id="preloader" />
    </>
  );
}

export default Home;
