import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const NewsSlideShow = () => {
  const slideImages = [
    {
      url: "https://medium.com/@marketing_18251/the-role-of-crypto-exchanges-and-wallets-in-cryptocurrency-adoption-530d15c050e6",
      caption:
        "The role of crypto exchanges and wallets in cryptocurrency adoption",
      background: "img/news/key.webp",
    },
    {
      url: "https://medium.com/@marketing_18251/the-impact-of-cryptocurrencies-on-global-and-local-payments-9d42d1bdbf5a",
      caption: "The impact of cryptocurrencies on global and local payments",
      background: "img/news/monitor.webp",
    },
    {
      url: "https://medium.com/@marketing_18251/regulation-of-cryptocurrencies-challenges-for-governments-and-businesses-worldwide-87746b70e729",
      caption:
        "Regulation of cryptocurrencies: Challenges for governments and businesses worldwide",
      background: "img/news/door.webp",
    },
    {
      url: "https://medium.com/@marketing_18251/the-growing-importance-of-stablecoins-in-the-crypto-scene-b838798b1499",
      caption: "The growing importance of stablecoins in the crypto scene",
      background: "img/news/phone.webp",
    },
    {
      url: "https://medium.com/@marketing_18251/nft-design-and-cryptocurrencies-creating-new-opportunities-995c3e8f6603",
      caption: "NFT Design and Cryptocurrencies Creating New Opportunities",
      background: "img/news/gallery.webp",
    },
    {
      url: "https://medium.com/@marketing_18251/the-role-of-institutional-investors-in-the-crypto-scene-opportunities-and-risks-9f414e1586ec",
      caption:
        "The role of institutional investors in the crypto scene: opportunities and risks",
      background: "img/news/skyscraper.webp",
    },
    {
      url: "https://medium.com/@marketing_18251/cryptocurrencies-and-environmental-protection-challenges-and-solutions-9eec12d295b",
      caption:
        "Cryptocurrencies and environmental protection: challenges and solutions",
      background: "img/news/grow.webp",
    },
    {
      url: "https://medium.com/@marketing_18251/smart-contracts-the-digital-security-revolution-ce7368ceba41",
      caption: "Smart Contracts: The Digital Security Revolution",
      background: "img/news/smart.webp",
    },
    {
      url: "https://medium.com/@marketing_18251/the-impact-of-blockchain-technology-on-traditional-companies-c06fa2c8e09d",
      caption: "The Impact of Blockchain Technology on Traditional Companies",
      background: "img/news/domino.webp",
    },
    {
      url: "https://medium.com/@marketing_18251/the-future-of-cryptocurrencies-hyperdeflationary-tokens-535410b02b26",
      caption: "The Future of Cryptocurrencies: Hyperdeflationary Tokens",
      background: "img/news/chart.webp",
    },
  ];

  const spanStyle = {
    padding: "20px",
    background: "rgba(98,52,236,0.7)",
    color: "#ffffff",
  };

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "350px",
    marginRight: "5px",
  };

  const responsiveSettings = [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  return (
    <Slide responsive={responsiveSettings} transitionDuration="350">
      {slideImages.map((slideImage, index) => (
        <div key={index}>
          <a href={slideImage.url} target="_blank">
            <div
              style={{
                ...divStyle,
                backgroundImage: `url(${slideImage.background})`,
              }}
            >
              <span style={spanStyle}>{slideImage.caption}</span>
            </div>
          </a>
        </div>
      ))}
    </Slide>
  );
};

export default NewsSlideShow;
