import Countdown from "react-countdown";

const COUNTDOWN_DATE_END = new Date("May 30, 2024");
COUNTDOWN_DATE_END.setHours(17);

function MyCountdown() {
  // Renderer callback
  const renderer = ({ total, days, hours, minutes, seconds }) => {
    if (total) {
      return (
        <div className="justify-content-center" style={{ display: "flex" }}>
          <NumberPillar number={(days < 10 ? "0" + days : days) + "d"} />
          &nbsp;&nbsp;
          <NumberPillar number={(hours < 10 ? "0" + hours : hours) + "h"} />
          &nbsp;&nbsp;
          <NumberPillar
            number={(minutes < 10 ? "0" + minutes : minutes) + "m"}
          />
          &nbsp;&nbsp;
          <NumberPillar
            number={(seconds < 10 ? "0" + seconds : seconds) + "s"}
          />
        </div>
      );
    } else {
      return (
        <h3
          style={{
            color: "white",
            fontWeight: 600,
          }}
        >
          <a href="https://www.bitmart.com/trade/en-US?symbol=HWT_USDT" target="_blank" style={{
            color: "lime",
            fontWeight: 600,
          }}>HWT is listed on BitMart! Click here to trade now!</a>
        </h3>
      );
    }
  };

  const NumberPillar = (props) => {
    const { number } = props;
    return (
      <p
        className="countdownElement"
        style={{
          padding: "5px",
          borderRadius: "10px",
          border: "1px",
          borderStyle: "solid",
          borderColor: "white",
          color: "white",
          fontSize: "28px",
          fontWeight: 750,
          marginBottom: 10,
          marginTop: 10,
        }}
      >
        {number}
      </p>
    );
  };

  return (
    <div
      className="countdown"
      style={{ textAlign: "center", marginBottom: 10 }}
    >
      <Countdown date={COUNTDOWN_DATE_END} renderer={renderer} />
    </div>
  );
}

export default MyCountdown;
